<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<visitmodals :modelName="modelName"></visitmodals>
		<customfields-modals></customfields-modals>
	</div>
</template>

<script>
import VisitModals from './VisitModals.vue';
import VisitActions from './VisitActions';

export default {
	name: 'visit-grid',
	components: {
		components: { visitmodals: VisitModals }
	},
	data() {
		return {
			modelName: 'visit',
			actions: VisitActions.gridactions,
			//closedStatus: 2,
			//visitsWithOpenIncidents: [],
			//visitsWithOpenRepairs: [],
			//incidentDescription: '',
			//repairDescription: '',
			//chats: [],
			//stops: [],
			//stopDescription: '',
			modelColumnDefs: {
				status: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				},
				notifications: {
					render: (data, type, row) => {
						let notification = '';

						if (row.incidentcount > 0) {
							let incidentDescription = this.$t('header.visit.incidentNotification');
							notification += `<i title='${incidentDescription}' style="color: red; padding-right: 10px" class="fak fa-solid-alert"></i>`;
						}

						if (row.repaircount > 0) {
							let repairDescription = this.$t('header.visit.repairNotification');
							notification += `<i title='${repairDescription}' style="color: red; padding-right: 10px" class="fas fa-hammer"></i>`;
						}

						if (row.chatcount > 0) {
							let chatDescription = this.$t('header.visit.chatNotification');
							notification += `<i title='${chatDescription}' style="color: red; padding-right: 10px" class="fak fa-solid-coment"></i>`;
						}
						if (row.stopcount == 0) {
							let stopDescription = this.$t('header.visit.noStopNotification');
							notification += `<i title='${stopDescription}' style="color: red; padding-right: 10px" class="fak fa-notification-no-berthing"></i>`;
						}
						if (row.inconsistencycount > 0) {
							let inconsistencyDescription = this.$t('header.visit.inconsistencyNotification');
							notification += `<i title='${inconsistencyDescription}' style="color: red; padding-right: 10px" class="fa-kit fa-solid-anomalies"></i>`;
						}

						if (row.delayeta || row.delayetd) {
							let delayeDescription = '';
							if (row.delayeta && row.delayetd) {
								delayeDescription = this.$t('header.visit.delayedetaetdNotification');
							} else if (row.delayeta) {
								delayeDescription = this.$t('header.visit.delayedetaNotification');
							} else {
								delayeDescription = this.$t('header.visit.delayedetdNotification');
							}
							notification += `<i title='${delayeDescription}' style="color: red; padding-right: 10px" class="fas fa-clock"></i>`;
						}

						return notification;
					}
				},
				highfrequency: {
					render: (data, type, row) => {
						return row.highfrequency ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				isautoata: {
					render: (data, type, row) => {
						return row.isautoata ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				isautoatd: {
					render: (data, type, row) => {
						return row.isautoatd ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	computed: {},
	methods: {
		/*
		getVisitsWithOpenIncidents() {
			const opts = {
				model: 'incident',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'statusid',
							op: 'ne',
							data: this.closedStatus
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					let allOpenIncidents = response.data.data;
					this.visitsWithOpenIncidents = allOpenIncidents.map((incident) => {
						return { visitid: incident.visitid, description: incident.description };
					});
				}
			});
		},
		getVisitsWithOpenRepairs() {
			const opts = {
				model: 'repair',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'statusid',
							op: 'ne',
							data: this.closedStatus
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					let allOpenRepairs = response.data.data;
					this.visitsWithOpenRepairs = allOpenRepairs.map((repair) => {
						return { visitid: repair.visitid, description: repair.description };
					});
				}
			});
		},
		getAllChatsByPA() {
			const userPorts = this.$store.getters.getPortsIds;
			const opts = {
				model: 'chatmessage',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'portid', op: 'in', data: userPorts }]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					let allChats = response.data.data;
					this.chats = allChats.map((chat) => {
						return { visitid: chat.visitid };
					});
				}
			});
		},
		getAllStopsByPA() {
			const userPorts = this.$store.getters.getPortsIds;
			const opts = {
				model: 'stop',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'portid', op: 'in', data: userPorts },
						{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data[0]) {
					let allStops = response.data.data;
					this.stops = allStops.map((stop) => {
						return { visitid: stop.visitid, statusid: stop.statusid };
					});
				}
			});
		}
		*/
	},
	mounted() {
		//this.getAllStopsByPA();
		//this.getVisitsWithOpenIncidents();
		//this.getVisitsWithOpenRepairs();
		//this.getAllChatsByPA();
	}
};
</script>
